import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useContext, useApi } from '../context';
import { Redirect, useHistory } from 'react-router-dom';
import utils from '../services/utils.service';
import { TransferWizard, TransferWizardStep } from './wizard';
import { useTranslation } from 'react-i18next';
import { Product } from '../services/types/product.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      padding: theme.spacing(0.25, 1),
    },
    listItemText: {
      margin: theme.spacing(0)
    },
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);


export const Review = () => {
  const context = useContext();
  const history = useHistory();
  const api = useApi();

  const [loading, setLoading] = React.useState(true);
  const [mandatoryProduct, setMandatoryProduct] = React.useState<Product | null>(null);

  const { t } = useTranslation(['checkout']);


  if (context.cartActions.getTotalAmount() === 0 || !context.data.vendorId) {
    return (
      <Redirect to='/vendors' />
    )
  }
  if (!localStorage.getItem('customer_token')) {
    return (
      <Redirect to='/checkout/customer' />
    )
  }

  React.useEffect(() => {
    utils.runAsync(async () => {
      const products = await api.getProducts(context.data.vendorId!);
      const orderCount = await api.getOrderCountForVendor(
        context.data.vendorId!,
        localStorage.getItem('customer_token')!
      );
      for (const product of products) {
        if (product.options.mandatoryOnFirstOrder && orderCount < 1) {
          const existingProduct = context.cartActions.getLines().find(l => l.product.id === product.id);
          if (!existingProduct || existingProduct.quantity < 1) {
            setMandatoryProduct(product);
            context.cartActions.increaseProductByOne(product);
          }
        }
      }
    }, (e) => {
      setLoading(false);
    });
  }, []);

  return (
    <TransferWizard
      canGoNext={!loading}
      loading={loading}
      back={() => history.goBack()}
      next={() => { history.push('/checkout/payment') }}
      step={TransferWizardStep.REVIEW}
      showButtons={true}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            {t('CHECKOUT_INTRO')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('PRODUCT')}</TableCell>
                  <TableCell align="right">{t('QUANTITY')}</TableCell>
                  <TableCell align="right">{t('AMOUNT')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {context.cartActions.getLines().map(l => (
                  <TableRow key={l.product.id}>
                    <TableCell>{l.product.name}</TableCell>
                    <TableCell align="right">{l.quantity}</TableCell>
                    <TableCell align="right">{utils.currencyFormat(l.quantity * l.product.price)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell rowSpan={2} />
                  <TableCell colSpan={1} align="right">{t('TOTAL_AMOUNT')}</TableCell>
                  <TableCell align="right">{utils.currencyFormat(context.cartActions.getTotalAmount())} {context.data.venue?.currency_iso_code}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {mandatoryProduct &&
          <Grid item xs={12}>
            <Typography variant={'h6'}>
              {t('MANDATORY_PRODUCT', { product: mandatoryProduct.name })}
            </Typography>
          </Grid>
        }
      </Grid>
    </TransferWizard>
  );
}
