import React, { Reducer, Dispatch } from 'react';
import { Api } from './services/api.service';
import { Config } from './config';
import { Venue } from './services/types/venue.type';
import { Product } from './services/types/product.type';

const config = new Config();

type CartItem = {
  product: Product;
  quantity: number;
}

type Data = {
  location?: string;
  venue?: Venue;
  vendorId?: number;
  requestedDatetime?: DeliveryDatetimeValues | undefined;
  allow_request_datetime?: boolean;
  cart: {
    [key: number]: CartItem | undefined
  }
 
};

interface DeliveryDatetimeValues {
  value: string;
  dbValue: Date;
}

export type Context = {
  data: Data;
  setData: Dispatch<Partial<Data>>;
  cartActions: {
    emptyCart: ()=>void,
    increaseProductByOne: (product: Product) => void,
    decreaseProdctByOne: (product: Product) => void,
    removeProduct: (product: Product) => void
    getProductQuantity: (productId: number) => number,
    getTotalAmount: () => number
    getLines: () => CartItem[]
  };
  selectedIputTime: (value: DeliveryDatetimeValues) => void;
  reset: () => void;
};



const GofunOnDemandContext = React.createContext<Context | undefined>(undefined);

export function useApi(): Api {
  const context = React.useContext(GofunOnDemandContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of GofunOnDemandProvider');
  }
  return new Api(config);
}

export function useContext(): Context {
  const context = React.useContext(GofunOnDemandContext);
  if (context === undefined) {
    throw new Error('Use context must be used inside of GofunOnDemandProvider');
  }
  return context;
}

export const GofunOnDemandProvider = ({ children }: { children: any }) => {
  const reducer: Reducer<Data, Partial<Data>> = (state: Data, action: Partial<Data>) => {
    return {
      ...state,
      ...action
    };
  }

  const [data, setData] = React.useReducer(
    reducer,
    {
      cart: {}
    }
  );

  const selectedIputTime = (value: DeliveryDatetimeValues) => {
    setData({...data, requestedDatetime: value})
  }

  const increaseProductByOne = (product: Product) => {
    if (data.cart[product.id]) {
      setData({ cart: { ...data.cart, [product.id]: { product, quantity: data.cart[product.id]!.quantity + 1 } } })
    } else {
      setData({ cart: { ...data.cart, [product.id]: { product, quantity: 1 } } })

    }
  }

  const decreaseProdctByOne = (product: Product) => {
    if (data.cart[product.id]) {
      if (data.cart[product.id]?.quantity == 1) {
        removeProduct(product);
      } else {
        setData({ cart: { ...data.cart, [product.id]: { product, quantity: data.cart[product.id]!.quantity - 1 } } })
      }
    }
  }

  const getShoppingCartTotalAmount = () => {
    const productLines = Object.values(data.cart);
    let amount = 0;
    for (const productLine of productLines) {
      if (productLine) {
        amount = amount + productLine.product.price * productLine.quantity;
      }
    }
    return amount;
  }

  const removeProduct = (product: Product) => {
    setData({ cart: { ...data.cart, [product.id]: undefined } })
  }


  const reset = () => {
    setData({ cart: {}, vendorId: undefined });
  }

  const emptyCart = () => {
    setData({ cart: {} });
  }

  return (
    <GofunOnDemandContext.Provider value={{
      data,
      setData,
      selectedIputTime,
      cartActions: {
        emptyCart,
        increaseProductByOne,
        decreaseProdctByOne,
        removeProduct,
        getTotalAmount: getShoppingCartTotalAmount,
        getProductQuantity: (productId: number) => data.cart[productId]?.quantity ? data.cart[productId]!.quantity : 0,
        getLines: () => Object.values(data.cart).filter(i => i !== undefined) as CartItem[],
      },
      reset
    }}>
      {children}
    </GofunOnDemandContext.Provider>
  )
}
