import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import koImg from '../assets/img/error.svg'
import { useApi, useContext } from '../context';
import { AlertDialog } from '../components/alertDialog';
import { LoadingButton } from '../components/loadingButton';
import { useParams } from 'react-router-dom';
import utils from '../services/utils.service';

export const PaymentKo = () => {
  const { t } = useTranslation('checkout');
  const api = useApi();

  const context = useContext();
  React.useEffect(() => {
    context.reset();
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const { id } = useParams() as any;


  const retryPayment = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const response = await api.getPaymentUrlForOrderId(id);
      window.location.replace(response.payment_url);
    }, (e) => {
      if (e) {
        setLoading(false);
        setError(true);
      }
    });
  }

  return (
    <Container style={{ height: '100%' }}>
      <AlertDialog
        open={error}
        onClose={() => setError(false)}
        message={t('PAYMENT_ERROR_CONTENT')}
        title={t('ERROR_TITLE')}
      />
      <Grid container justify='center' alignContent='center' spacing={4} style={{ height: '100%' }}>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <Typography variant='h4' align='center'>
            {t('PAYMENT_KO_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' align='center'>
            {t('PAYMENT_KO_SUBTITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justify='center'>
          <img src={koImg} style={{ maxWidth: 200 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>
            {t('PAYMENT_KO_DESCRIPTION')}
          </Typography>
        </Grid>
        <Grid item container justify='center' xs={12}>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={retryPayment}
            variant='contained'
            color='primary'
            size='large'>
            {t('RETRY_PAYMENT')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Container>
  );
}