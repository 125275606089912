import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route as DomRoute,
  useLocation,
  Redirect
} from 'react-router-dom';
import { Home } from './home/home';
import { PaymentKo } from './payment/paymentKo';
import { PaymentOk } from './payment/paymentOk';
import { Layout } from './layout/layout';
import { Payment } from './checkout/payment';
import { Customer } from './checkout/customer';
import { DeliveryTime } from './checkout/deliveryTime';
import { Review } from './checkout/review';
import { Vendors } from './vendors/vendors';
import { Products } from './vendors/products';

const Route = ({ component: Component, ...rest }: { component: any, exact?: boolean, path: string[] | string }) => (
  <DomRoute {...rest} render={(props) => <Layout {...rest} {...props}><Component {...props} /></Layout>} />
)

const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    setTimeout(() => {
    window.scrollTo(0, 0);
    }, 25)
  }, [pathname]);
  return null;
}

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path='/checkout/deliveryTIme' component={DeliveryTime} />
        <Route exact path='/checkout/customer' component={Customer} />
        <Route exact path='/checkout/payment' component={Payment} />
        <Route exact path='/checkout/review' component={Review} />
        <Route exact path='/payments/redsys/ko/:id' component={PaymentKo} />
        <Route exact path='/payments/redsys/ok' component={PaymentOk} />
        <Route exact path='/payments/ondelivery/ok' component={PaymentOk} />
        <Route exact path='/vendors' component={Vendors} />
        <Route exact path='/vendors/:id/products' component={Products} />
        <Route exact path='/' component={Home} />
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
}
