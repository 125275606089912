import React from 'react';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';

moment.locale('en');

export const DatePickerProvider = ({ children }: { children: any }) => {
  const { i18n } = useTranslation();
  return (
    <MuiPickersUtilsProvider libInstance={moment} locale={i18n.language} utils={MomentUtils}>
      {children}
    </MuiPickersUtilsProvider>
  )
}