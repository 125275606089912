import 'fontsource-roboto';
import React from 'react';
import { render } from 'react-dom';
import App from './app';
import { Config } from './config';
import './i18n';
import 'react-typed/dist/animatedCursor.css';

const config = new Config();


render(<App />, document.getElementById('root'));

if (module.hot) {
    module.hot.accept();
}