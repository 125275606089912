import { hot } from 'react-hot-loader';
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Router } from './router';
import { GofunOnDemandProvider } from './context';
import { DatePickerProvider } from './components/datePicketProvider';


const lightTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#674aee',
      main: '#231EBA',
      dark: '#000089',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#2a2d5a',
      main: '#000330',
      dark: '#00000a',
      contrastText: '#ffffff',
    },
  },
}));

const darkTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#80b5ff',
      dark: '#005ac1',
      main: '#4186f5',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#ffffff',
      contrastText: '#000000',
    },
  },
}));


const App = () => {
  const [theme, setTheme] = React.useState(lightTheme);
  React.useEffect(() => {
    const darkListener = (e: any) => e.matches && setTheme(darkTheme);
    const lightListener = (e: any) => e.matches && setTheme(lightTheme);
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme(darkTheme);
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change',darkListener);
      window.matchMedia('(prefers-color-scheme: light)').addEventListener('change',lightListener);
      return (() => {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change',darkListener);
        window.matchMedia('(prefers-color-scheme: light)').removeEventListener('change',lightListener);
      });
    }
  }, []);
  return (
    <React.Fragment>
      <DatePickerProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GofunOnDemandProvider>
            <Router />
          </GofunOnDemandProvider>
        </ThemeProvider>
      </DatePickerProvider >
    </React.Fragment>
  )
}

export default hot(module)(App);
