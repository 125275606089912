import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  useMediaQuery,
  useTheme,
  StepContent,
  Grid,
  Button,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../components/loadingButton';
import { useApi, useContext } from '../context';
import utils from '../services/utils.service';
import { Vendor } from '../services/types/vendor.type';

export enum TransferWizardStep {
  DELIVERY_TIME, CUSTOMER, REVIEW, PAYMENT
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingTop: theme.spacing(3)
    },
    stepper: {
      padding: 0,
      backgroundColor: 'inherit'
    }
  }),
);

export const TransferWizard = ({
  children,
  step,
  next,
  back,
  canGoNext = true,
  showButtons = true,
  loading = false
}: {
  children: any,
  step: TransferWizardStep,
  next: () => void,
  back?: () => void,
  canGoNext: boolean,
  loading?: boolean,
  showButtons?: boolean
}) => {
  const { t } = useTranslation('checkout');
  const api = useApi();
  const context = useContext();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [vendorDataApi, setVendorDataApi] = useState<Vendor | null | undefined>(null)
  const [loadingState, setLoadingState] = useState(false)

  React.useEffect(() => {
    if(context.data.vendorId) {
      utils.runAsync(async () => {
        const vendorData = await api.getVendor(context.data.vendorId!)
        setVendorDataApi(vendorData)
      }, e => {
        setLoadingState(false)
      })
    } else {
      setLoadingState(false)
    }
  }, [])
  

  const getStepProps = (currentStep: TransferWizardStep) => {
    return {
      key: currentStep,
      completed: step > currentStep,
      active: currentStep === step
    }
  }
  const getStepContent = (currentStep: TransferWizardStep) => {

  

    if (isXs && currentStep === step) {
      return (
        <StepContent>
          <Grid container spacing={4}>
            <Grid item>
              {children}
            </Grid>
            {getButtons()}
          </Grid>
        </StepContent>
      );
    }
    else if (isXs) return <StepContent />;
    else return null;
  }

  const getButtons = () => {
    if (!showButtons) return null;
    return (
      <Grid item xs={12}>
        <Grid container spacing={2} justify='flex-end'>
          <Grid item>
            <Button
              disabled={false}
              onClick={() => {
                if (back) {
                  back();
                }
              }}>
              {t('BACK')}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant='contained'
              color='secondary'
              disabled={!canGoNext || loading}
              loading={loading}
              onClick={next}>
              {step === TransferWizardStep.PAYMENT ? t('FINISH') : t('NEXT')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const getStepper = () => (
    <Grid container direction='column'>
      <Grid container item justify='center'>
        <Typography variant='h4' gutterBottom>
          {context.data.location}
        </Typography>
      </Grid>
      <Grid item>
        <Stepper
          orientation={isXs ? 'vertical' : 'horizontal'}
          className={classes.stepper}
          alternativeLabel={!isXs}>
           {
            vendorDataApi?.allowRequestData ? (
            <Step {...getStepProps(TransferWizardStep.DELIVERY_TIME)}>
              <StepLabel>{t('DELIVERY_TIME')}</StepLabel>
              {getStepContent(TransferWizardStep.DELIVERY_TIME)}
            </Step>
            ) : null
           }
          <Step {...getStepProps(TransferWizardStep.CUSTOMER)}>
            <StepLabel>{t('CUSTOMER')}</StepLabel>
            {getStepContent(TransferWizardStep.CUSTOMER)}
          </Step>
          <Step {...getStepProps(TransferWizardStep.REVIEW)}>
            <StepLabel>{t('REVIEW')}</StepLabel>
            {getStepContent(TransferWizardStep.REVIEW)}
          </Step>
          <Step {...getStepProps(TransferWizardStep.PAYMENT)}>
            <StepLabel>{t('CHECKOUT')}</StepLabel>
            {getStepContent(TransferWizardStep.PAYMENT)}
          </Step>
        </Stepper>
      </Grid>
      {!isXs &&
        <Grid item>
          <Box padding={2}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                {children}
              </Grid>
              {getButtons()}
            </Grid>
          </Box>
        </Grid>
      }
    </Grid>
  )


  return (
    <Container>
      <Typography variant='h3' style={{ marginBottom: 15 }}>
        {t('CHECKOUT_TITLE')}
      </Typography>
      {isXs &&
        getStepper()
      }
      {!isXs &&
        <Paper className={classes.paper}>
          {getStepper()}
        </Paper>
      }
    </Container>
  );
}