import React, { useState } from 'react';
import { Grid, Typography, CircularProgress, TextField, makeStyles } from '@material-ui/core';
import { useContext, useApi } from '../context';
import utils from '../services/utils.service';
import { useHistory, Redirect } from 'react-router-dom';
import { TransferWizard, TransferWizardStep } from './wizard';
import { useTranslation } from 'react-i18next';
import { AlertDialog } from '../components/alertDialog';
import { Vendor } from '../services/types/vendor.type';

const phoneRegexp = new RegExp('^[0-9]{8,15}$');
const emailRegexp = new RegExp('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,6})+$');

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});


type Form = {
  name: string;
  phone: string;
  email: string;
}

type FormErrors = {
  name: string | undefined;
  phone: string | undefined;
  email: string | undefined;
}

type FormWithErrors = {
  form: Form;
  errors: FormErrors;
}

export const Customer = () => {
  const api = useApi();
  const context = useContext();
  const history = useHistory();
  const [vendorDataApi, setVendorDataApi] = useState<Vendor | null | undefined>(null)

  const emptyFormErrors = {
    name: undefined,
    phone: undefined,
    email: undefined,
  };

  const emptyForm = {
    name: '',
    phone: '',
    email: '',
  }

  const { t } = useTranslation(['checkout']);

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [savingCustomer, setSavingCustomer] = React.useState(false);
  const [formWithErrors, setFormWithErrors] = React.useReducer((state: FormWithErrors, action: { form?: Partial<Form>, errors?: Partial<FormErrors> }) => {
    const newFormWithErrors: FormWithErrors = { ...state };
    if (action.form) {
      newFormWithErrors.form = {
        ...state.form,
        ...action.form
      }
    }
    if (action.errors) {
      newFormWithErrors.errors = {
        ...state.errors,
        ...action.errors
      }
    }
    return newFormWithErrors;
  }, { form: emptyForm, errors: emptyFormErrors });

  React.useEffect(() => {
    if (localStorage.getItem('customer_token')) {
      utils.runAsync(async () => {
        const customer = await api.getCustomer(localStorage.getItem('customer_token')!);
        setFormWithErrors({
          form: {
            name: customer.name,
            phone: customer.phone,
            email: customer.email
          }
        })
      }, (e) => {
        setLoading(false);
      })
    } else {
      setLoading(false);
    }
  }, []);

  const validate = () => {
    const errors = Object.assign({}, emptyFormErrors);
    if (form.name.trim().length === 0) {
      errors.name = t('REQUIRED');
    }
    if (form.phone.trim().length === 0) {
      errors.phone = t('REQUIRED');
    } else if (!phoneRegexp.test(form.phone.trim())) {
      errors.phone = t('INVALID_PHONE');
    }
    if (form.email.trim().length === 0) {
      errors.email = t('REQUIRED');
    } else if (!emailRegexp.test(form.email.trim())) {
      errors.email = t('INVALID_EMAIL');
    }
    const isValid = Object.keys(errors).find(k => (errors as any)[k] !== undefined) === undefined;
    if (!isValid) {
      setFormWithErrors({ errors });
    }
    return isValid;
  }

  const next = () => {
    if (!validate()) {
      return false;
    }
    utils.runAsync(async () => {
      setSavingCustomer(true);
      const customer = {
        name: form.name.trim(),
        phone: form.phone.trim(),
        email: form.email.trim()
      }
      const token = await api.upsertCustomer(customer, localStorage.getItem('customer_token'));
      if (token) {
        localStorage.setItem('customer_token', token);
      }
      history.push('/checkout/review');
    }, (e) => {
      setSavingCustomer(false);
      if (e) {
        setError(true);
      }
    });
  }
  const classes = useStyles();


  const form = formWithErrors.form;
  const formErrors = formWithErrors.errors;

  if (context.cartActions.getTotalAmount() === 0 || !context.data.vendorId) {
    return (
      <Redirect to='/vendors' />
    )
  }

  return (
    <>
      <AlertDialog
        title={t('ERROR_TITLE')}
        message={t('CUSTOMER_UPSERT_ERROR_MESSAGE')}
        open={error}
        onClose={() => setError(false)}
      />
      <TransferWizard
        step={TransferWizardStep.CUSTOMER}
        back={() => history.goBack()}
        canGoNext={!savingCustomer && !loading}
        loading={savingCustomer}
        next={next}
        showButtons={true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('BENEFICIARY_INTRO')}
            </Typography>
          </Grid>
          {loading &&
            <Grid item container justify='center'>
              <CircularProgress color='primary' />
            </Grid>
          }
          {!loading &&
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  label={t('NAME')}
                  error={formErrors.name !== undefined}
                  helperText={formErrors.name}
                  variant='outlined'
                  value={form.name}
                  onChange={(event) => {
                    setFormWithErrors({ form: { name: event.target.value }, errors: { name: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  label={t('PHONE')}
                  variant='outlined'
                  value={form.phone}
                  error={formErrors.phone !== undefined}
                  helperText={formErrors.phone}
                  onChange={(event) => {
                    setFormWithErrors({ form: { phone: event.target.value }, errors: { phone: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth={true}
                  label={t('EMAIL')}
                  variant='outlined'
                  value={form.email}
                  error={formErrors.email !== undefined}
                  helperText={formErrors.email}
                  onChange={(event) => {
                    setFormWithErrors({ form: { email: event.target.value }, errors: { email: undefined } });
                  }}
                />
              </Grid>
            </>
          }
        </Grid>
      </TransferWizard>
    </>
  );
}