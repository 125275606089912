import React from 'react';
import {
  Typography,
  Grid,
  Container,
  useTheme,
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useApi, useContext } from '../context';
import { Vendor } from '../services/types/vendor.type';
import utils from '../services/utils.service';
import { useHistory } from 'react-router-dom';
import { AlertDialog } from '../components/alertDialog';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
    },
    media: {
      height: 240,
    },
  })
)

const VendorCard = ({ vendor, onClick }: { vendor: Vendor, onClick: (vendor: Vendor) => void }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => {
        onClick(vendor);
      }}>
        <CardMedia
          className={classes.media}
          image={vendor.cover_image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {vendor.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {vendor.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export const Vendors = () => {
  const { t } = useTranslation('vendors');
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const [vendors, setVendors] = React.useState<Vendor[]>([]);
  const [loading, setLoading] = React.useState(true);

  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    context.reset();
    utils.runAsync(async () => {
      setLoading(true);
      setVendors(await api.listVendors(context.data.venue!.id));
    }, (e) => {
      setLoading(false);
    })
  }, []);

  const onVendorSelected = (vendor: Vendor) => {
    if (!vendor.enabled || !context.data.venue?.enabled) {
      setError(true);
    } else {
      context.setData({ vendorId: vendor.id });
      history.push(`/vendors/${vendor.id}/products`);
    }
  }

  return (
    <Container>
      <AlertDialog
        open={error}
        title={t('VENDOR_NOT_AVAILABLE_TITLE')}
        message={t('VENDOR_NOT_AVAILABLE_MESSAGE')}
        onClose={() => setError(false)}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h2'} gutterBottom={true}>
            {t('VENDORS_AT_VENUE', { venue: context.data.venue?.name })}
          </Typography>
          <Typography variant={'subtitle1'}>
            {t('SELECT_VENDOR_INSTRUCTIONS')}
          </Typography>
        </Grid>
        {loading &&
          <Grid container justify='center' item xs={12}>
            <CircularProgress color='primary' />
          </Grid>
        }
        {vendors.map(v => (
          <Grid key={v.id} item xs={12} sm={6} md={4} lg={3}>
            <VendorCard vendor={v} onClick={onVendorSelected} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}