import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Link,
  Box,
} from '@material-ui/core';
import { useContext, useApi } from '../context';
import { Redirect, useHistory } from 'react-router-dom';
import { AlertDialog } from '../components/alertDialog';
import { TransferWizard, TransferWizardStep } from './wizard';
import { Trans, useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    listItem: {
      padding: theme.spacing(0.25, 1),
    },
    listItemText: {
      margin: theme.spacing(0)
    }
  }),
);

type DeliveryDatetimeValues = {
  value: string
  dbValue: string
}

const getOptionsValues = () => {
  const currentTime = moment()
  const initialTime = currentTime.clone().startOf('day').set('hour', 11).set('minutes', 0)
  const finalTime = currentTime.clone().startOf('day').set('hour', 19).set('minutes', 30)
  let indexTime = initialTime.clone()

  const availableTimes = []
  do {
    if (indexTime.isSameOrAfter(currentTime.clone().add(10, 'minutes'))) {
      availableTimes.push(indexTime)
    }
    indexTime = indexTime.clone().add(10, 'minutes')
  } while (indexTime.isBefore(finalTime))

  return availableTimes.map(x => {
    return {value: x.format("HH:mm").toString(), dbValue: x.toISOString()}
  })
};

const inputArray = getOptionsValues()
const date = new Date()
if(date.getHours() > 10 && date.getMinutes() > 30){
  inputArray.shift()
}

export const DeliveryTime = () => {
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const { t } = useTranslation(['checkout']);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedOptionValue, setSelectedOptionValue] = React.useState<DeliveryDatetimeValues | null>(null);
  const selectedValue = context.selectedIputTime

  useEffect(() => {
    //selectedOptionValue at this point is undefined 
    selectedValue(selectedOptionValue)
  }, [selectedOptionValue])

  const classes = useStyles();

  if (context.cartActions.getTotalAmount() === 0 || !context.data.vendorId) {
    return (
      <Redirect to='/vendors' />
    )
  }
  
  return (
    <TransferWizard
      step={TransferWizardStep.DELIVERY_TIME}
      canGoNext={!loading && selectedOptionValue !== null}
      loading={loading}
      back={() => history.goBack()}
      next={() => history.push('/checkout/customer')}
      showButtons={true}>
      <AlertDialog
        open={error}
        title={t('ERROR_TITLE')}
        message={t('PAYMENT_ERROR_CONTENT')}
        onClose={() => {
          setError(false)
        }}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            {t('DELIVERY_INTRO')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disableClearable={true}
            options={inputArray}
            onChange={(event: any, value: DeliveryDatetimeValues | null) => setSelectedOptionValue(value) }
            value={selectedOptionValue}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.value}
            getOptionSelected={(option, value) => option === value}
            renderOption={(option) => (
              <React.Fragment>
                <span style={{ marginLeft: 10 }}>{option.value}</span>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField  
                {...params} required
                label={t('DELIVERY_HOUR')}
                variant='outlined'
                error={false}
                helperText={false}
                fullWidth={true}
              />
            )}
          />
        </Grid>
      </Grid>
    </TransferWizard>
  );
}
