import { DateType } from '@date-io/type';
import { DateTimePickerProps } from '@material-ui/pickers';
import { Config } from '../config';
import { Customer } from './types/customer.type';
import { Product } from './types/product.type';
import { Vendor } from './types/vendor.type';
import { Venue } from './types/venue.type';

export class Api {

  constructor(
    private readonly config: Config,
  ) { }

  async getVenue(id: number): Promise<Venue> {
    const response = await fetch(this.config.API_BASE_URL + '/venues/' + id);
    if (!response.ok) {
      throw new Error('Could not fetch venue');
    }
    return response.json();
  }

  async listVendors(venueId: number): Promise<Vendor[]> {
    const response = await fetch(this.config.API_BASE_URL + '/venues/' + venueId + '/vendors');
    if (!response.ok) {
      throw new Error('Could not fetch vendors');
    }
    return (await response.json()).vendors;
  }
  async getVendor(vendorId: number): Promise<Vendor> {
    const response = await fetch(this.config.API_BASE_URL + '/vendors/' + vendorId);
    if (!response.ok) {
      throw new Error('Could not get vendor');
    }
    return response.json();
  }

  async getOrderCountForVendor(vendorId:number, token:string):Promise<number>{
    const response = await fetch(
      this.config.API_BASE_URL + '/customers/vendors/'+vendorId+'/orders-count', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (!response.ok) {
      throw new Error('Could not get order count');
    }
    return (await response.json()).count;
  }


  async getProducts(vendorId: number): Promise<Product[]> {
    const response = await fetch(this.config.API_BASE_URL + '/vendors/' + vendorId + '/products');
    if (!response.ok) {
      throw new Error('Could not fetch products');
    }
    return (await response.json()).products;
  }

  async upsertCustomer(
    customer: Customer,
    token?: string | null
  ): Promise<string | null> {
    let method = 'POST';
    let headers: any = {
      'content-type': 'application/json'
    }
    if (token) {
      method = 'PUT';
      headers['Authorization'] = `Bearer ${token}`;
    }
    const response = await fetch(
      this.config.API_BASE_URL + '/customers',
      {
        method,
        headers,
        body: JSON.stringify(customer)
      }
    );
    if (!response.ok) {
      throw new Error('Could not upsert customer');
    }
    if (!token) {
      return (await response.json()).token;
    }
    return null;
  }

  async getCustomer(token: string): Promise<Customer> {
    const response = await fetch(
      this.config.API_BASE_URL + '/customers/me', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (!response.ok) {
      throw new Error('Could not get customer');
    }
    return response.json();
  }


  async checkout(
    data: {
      vendor_id: number,
      venue_id: number,
      payment_method: string,
      requested_datetime: Date,
      location: string,
      products: Array<{
        id: number;
        quantity: number;
      }>,
         
    },
    token: string
  ): Promise<{ order_reference: string, payment_url: string }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/checkout',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }
    );
    if (!response.ok) {
      throw new Error('Could not checkout');
    }
    return response.json();
  }

  async getPaymentUrlForOrderId(
    id: string,
  ): Promise<{ payment_url: string }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/orders/' + id + '/payment-url',
      { method: 'GET' }
    );
    if (!response.ok) {
      throw new Error('Could not get payment url');
    }
    return response.json();
  }

}