import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';


export const AlertDialog = ({
  open,
  onClose,
  title,
  message
}: {
  open: boolean,
  onClose: () => void,
  title: string
  message: string
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog'
    >
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='inherit' autoFocus>
          {'Ok'}
        </Button>
      </DialogActions>
    </Dialog >
  )
}
