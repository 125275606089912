import React from 'react';
import {
  Typography,
  Grid,
  Container,
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CardHeader,
  Avatar,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core';

import { useTranslation, Trans } from 'react-i18next';
import { useApi, useContext } from '../context';
import { Vendor } from '../services/types/vendor.type';
import utils from '../services/utils.service';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { Product } from '../services/types/product.type';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
    },
    media: {
      height: 220,
    },
    avatar: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText
    },
    cardActions: {
      justifyContent: 'center'
    }
  })
)

const ProductCard = ({
  product,
  onPlus,
  onMinus,
  onRemove,
}: {
  product: Product,
  onPlus: (product: Product) => void,
  onMinus: (product: Product) => void,
  onRemove: (product: Product) => void
}) => {
  const classes = useStyles();
  const context = useContext();
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {context.cartActions.getProductQuantity(product.id)}
          </Avatar>
        }
        disableTypography={true}
        title={
          <Typography variant={'h6'}>
            {product.name}
          </Typography>
        }
        subheader={
          <Typography variant={'subtitle1'}>
            {utils.currencyFormat(product.price)} {context.data.venue?.currency_iso_code}
          </Typography>
        }
        action={
          <IconButton
            disabled={context.cartActions.getProductQuantity(product.id) === 0}
            color='secondary'
            aria-label="settings"
            onClick={() => onRemove(product)}>
            <RemoveShoppingCartIcon />
          </IconButton>
        }
      />
      <CardMedia
        className={classes.media}
        image={product.cover_image}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {product.description}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <IconButton
          disabled={context.cartActions.getProductQuantity(product.id) === 0}
          color='secondary'
          onClick={() => onMinus(product)}>
          <RemoveCircleIcon fontSize='large' />
        </IconButton>
        <IconButton color='secondary' onClick={() => onPlus(product)}>
          <AddCircleIcon fontSize='large' />
        </IconButton>
      </CardActions>
    </Card>
  )
}

export const Products = () => {
  const { t } = useTranslation('vendors');
  const context = useContext();
  const api = useApi();
  const history = useHistory();
  const params: any = useParams();
  const vendorId = params.id;

  const [vendor, setVendor] = React.useState<Vendor | null>(null);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = React.useState<Product[]>([]);
  const [loading, setLoading] = React.useState(true);

  const [tags, setTags] = React.useState<string[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);


  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoading(true);
      setVendor(await api.getVendor(vendorId));
      const products = (await api.getProducts(vendorId)).filter(p => p.enabled);
      setProducts(products);
      setFilteredProducts(products);
      const pTags: any = {};
      for (const product of products) {
        for (const tag of product.tags) {
          pTags[tag.toUpperCase()] = true;
        }
      }
      setTags(Object.keys(pTags));
    }, (e) => {
      setLoading(false);
      if (e) {
        history.push('/vendors');
      }
    })
  }, []);

  React.useEffect(() => {
    if (selectedTags.length === 0) {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(products.filter(p => {
        for (const tag of p.tags) {
          if (selectedTags.includes(tag.toUpperCase())) {
            return true;
          }
        }
        return false;
      }))
    }

  }, [selectedTags]);

  const onPlus = (product: Product) => {
    context.cartActions.increaseProductByOne(product);
  }

  const onMinus = (product: Product) => {
    context.cartActions.decreaseProdctByOne(product);
  }

  const onRemove = (product: Product) => {
    context.cartActions.removeProduct(product);
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container direction='row' alignItems='center' spacing={1}>
            <Grid item>
              <IconButton edge='start' onClick={() => {
                history.push('/vendors');
              }}>
                <KeyboardBackspaceIcon fontSize='large' />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant={'h3'} gutterBottom={false}>
                {t('PRODUCTS_FOR_VENDOR', { vendor: vendor?.name ? vendor.name : '' })}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant={'subtitle1'}>
            {t('SELECT_PRODUCTS_DESCRIPTION')}
          </Typography>
        </Grid>
        {loading &&
          <Grid container justify='center' item xs={12}>
            <CircularProgress color='secondary' />
          </Grid>
        }
        {!loading && tags.length > 0 &&
          <Grid item xs={12}>
            <Autocomplete
              multiple
              onChange={(e, value, reason) => {
                setSelectedTags(value);
              }}
              options={tags}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('FILTER_BY_TAG')}
                />
              )}
            />
          </Grid>
        }

        {filteredProducts.map(p => (
          <Grid key={p.id} item xs={12} sm={6} md={4} lg={3}>
            <ProductCard product={p} onPlus={onPlus} onMinus={onMinus} onRemove={onRemove} />
          </Grid>
        ))}
        {!loading &&
          <Grid container item xs={12} justify='center' spacing={2}>
            <Grid item>
              <Button
                size='large'
                color='secondary'
                disabled={context.cartActions.getTotalAmount() === 0}
                onClick={() => {
                  context.cartActions.emptyCart();
                }}
              >
                {t('EMPTY_CART')}
              </Button>

            </Grid>
            <Grid item>
              {
                !vendor?.allowRequestData ? (
                  <Button
                  size='large'
                  component={RouterLink}
                  to='/checkout/customer'
                  color='secondary'
                  variant='outlined'
                  startIcon={<ShoppingCartIcon />}
                  disabled={context.cartActions.getTotalAmount() === 0}
                >
                  {utils.currencyFormat(context.cartActions.getTotalAmount())} {context.data.venue?.currency_iso_code}
                </Button>
                ) : (
                  <Button
                  size='large'
                  component={RouterLink}
                  to='/checkout/deliveryTIme'
                  color='secondary'
                  variant='outlined'
                  startIcon={<ShoppingCartIcon />}
                  disabled={context.cartActions.getTotalAmount() === 0}
                >
                  {utils.currencyFormat(context.cartActions.getTotalAmount())} {context.data.venue?.currency_iso_code}
                </Button>
                )
              }

            </Grid>
          </Grid>
        }
      </Grid>
    </Container>
  )
}