import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { useTheme, Grid, Typography, Link, List, ListItem, ListItemText, IconButton, CircularProgress } from '@material-ui/core';
import { Paper, Container } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import logo from '../assets/img/logo.svg';
import logoWhite from '../assets/img/logo-white.svg';
import easygobandWhite from '../assets/img/easygoband-white.svg';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { useApi, useContext } from '../context';
import koImg from '../assets/img/error.svg';
import { Venue } from '../services/types/venue.type';
import utils from '../services/utils.service';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logoContainer: {
      flexGrow: 1,
    },
    logo: {
      height: 40,
      maxWidth: '100%'
    },
    container: {
      flexGrow: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6)
    },
    footerContainer: {
      backgroundColor: theme.palette.primary.main,
    },
    footerWrapper: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    footerText: {
      color: theme.palette.primary.contrastText
    },
    toolBarContainer: {
      paddingLeft: 0,
      paddingRight: 0
    },
    egobLogo: {
      width: 300,
      maxWidth: '100%'
    },
    fullHeight: {
      height: '100%'
    }
  }),
);

const InvalidVenue = () => {
  const { t } = useTranslation('layout');
  const classes = useStyles();
  return (
    <Container className={classes.fullHeight}>
      <Grid className={classes.fullHeight} container justify='center' alignContent='center' spacing={1}>
        <Grid item xs={12}>
          <Typography variant='h4' align='center'>
            {t('INVALID_LINK_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' align='center'>
            {t('INVALID_LINK_SUBTITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} container justify='center'>
          <img src={koImg} style={{ maxWidth: 200 }} />
        </Grid>
        <Grid item xs={12}>
          <Typography align='center'>
            {t('INVALID_LINK_EXTENDED_DESCRIPTION')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

const Loading = () => {
  const classes = useStyles();
  return (
    <Container className={classes.fullHeight}>
      <Grid className={classes.fullHeight} container justify='center' alignContent='center' spacing={1}>
        <CircularProgress color='primary' />
      </Grid>
    </Container>
  )
}

export const Layout = ({ children, ...props }: { children: any }) => {
  const classes = useStyles();
  const { t } = useTranslation('layout');
  const theme = useTheme();
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const [venueData, setVenueData] = React.useState<Venue | null>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let venue = localStorage.getItem('venue');
    let location = localStorage.getItem('location');
    if (urlParams.get('venue') && urlParams.get('location')) {
      venue = urlParams.get('venue')!;
      location = urlParams.get('location')!;
      localStorage.setItem('venue', urlParams.get('venue')!);
      localStorage.setItem('location', urlParams.get('location')!);
    }
    if (venue && parseInt(venue, 10) && location) {
      utils.runAsync(async () => {
        setLoading(true);
        const venueResponse = await api.getVenue(parseInt(venue!, 10))
        context.setData({ venue: venueResponse, location: location! });
        setVenueData(venueResponse);
      }, (e) => {
        setLoading(false);
      })
    }
  }, []);

  const shouldShowNewOrderButton = () => {
    return venueData !== null && (
      (props as any).path === ('/') ||
      (props as any).path.includes('/payments')
    );
  }

  const shouldShowCartButton = () => {
    return (props as any).path.includes('/products') || (props as any).path.includes('/checkout');
  }

  const shouldShowFooterLinks = () => {
    return venueData !== null;
  }

  const getContent = () => {
    if (loading) {
      return (<Loading />);
    } else if (venueData !== null) {
      return children;
    } else {
      return (<InvalidVenue />);
    }
  }

  return (
    <div className={classes.root}>
      <MetaTags>
        <title>{t('TITLE')}</title>
        <meta name='description' content={t('DESCRIPTION')} />
      </MetaTags>
      <AppBar position='static' color='transparent' variant='outlined'>
        <Container className={classes.toolBarContainer}>
          <Toolbar>
            <Grid container alignItems='center'>
              <Grid item xs={4} >
                <Grid container justify={'flex-start'}>
                  <Grid item>
                    <Link component={RouterLink} to='/'>
                      <img className={classes.logo} src={theme.palette.type === 'light' ? logo : logoWhite} />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}  >
                <Grid container justify={'flex-end'} spacing={2} alignItems='center'>
                  {shouldShowNewOrderButton() &&
                    <Grid item>
                      <Button component={RouterLink} to='/vendors' variant='contained' color='secondary'>{t('NEW_ORDER')}</Button>
                    </Grid>
                  }
                  {shouldShowCartButton() &&
                    <>
                      <Grid item>
                        <IconButton
                          disabled={context.cartActions.getTotalAmount() === 0}
                          color='secondary'
                          onClick={() => {
                            context.cartActions.emptyCart()
                          }}>
                          <RemoveShoppingCartIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Button
                          component={RouterLink}
                          to='/checkout/customer'
                          color='secondary'
                          variant='outlined'
                          startIcon={<ShoppingCartIcon />}
                          disabled={context.cartActions.getTotalAmount() === 0}
                        >
                          {utils.currencyFormat(context.cartActions.getTotalAmount())} {venueData?.currency_iso_code}
                        </Button>
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <div className={classes.container}>
        {getContent()}
      </div>
      <Paper className={classes.footerContainer} variant='outlined' square={true}>
        <Container className={classes.footerWrapper}>
          <Grid container spacing={2}>
            <Grid item md={shouldShowFooterLinks() ? 6 : 12} xs={12}>
              <Grid container justify='center' alignContent='center' alignItems='center' spacing={1}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Grid item>
                      <img className={classes.egobLogo} src={easygobandWhite} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Trans t={t} i18nKey='EASYGOBAND_INFO'>
                    <Typography variant='body2' align='center' className={classes.footerText}>
                      {'GOFUN_PRODUCT'}
                      <Link
                        className={classes.footerText}
                        href='https://www.easygoband.com/plataforma-gofun/'
                        target='_blank'
                        rel='noopener'>
                        {'GOFUN_LINK'}
                      </Link>
                    </Typography>
                  </Trans>
                </Grid>
                <Grid item>
                  <IconButton component='a' rel='noopener' target='_blank' href='https://www.instagram.com/easygoband/' aria-label='instagram'>
                    <InstagramIcon fontSize={'large'} className={classes.footerText} />
                  </IconButton>
                  <IconButton component='a' rel='noopener' target='_blank' href='https://www.linkedin.com/company/11270892/' aria-label='linkedin'>
                    <LinkedInIcon fontSize={'large'} className={classes.footerText} />
                  </IconButton>
                  <IconButton component='a' rel='noopener' target='_blank' href='https://www.facebook.com/easygoband' aria-label='facebook'>
                    <FacebookIcon fontSize={'large'} className={classes.footerText} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {shouldShowFooterLinks() &&
              <>
                <Grid item direction='column' md={3} xs={12} sm={6} container justify='flex-start' alignItems='center' alignContent='center'>
                  <Grid item>
                    <Typography variant='h6' className={classes.footerText} align='center'>{t('LINKS')}</Typography>
                  </Grid>
                  <Grid item>
                    <List dense>
                      <ListItem component={Link} href='https://www.easygoband.com/blog/' target='_blank' rel='noopener' className={classes.footerText}>
                        <ListItemText primary={t('ABOUT_US')} />
                      </ListItem>
                      <ListItem component={Link} href='https://www.easygoband.com/politica-de-privacidad/' target='_blank' rel='noopener' className={classes.footerText}>
                        <ListItemText primary={t('PRIVACY_POLICY')} />
                      </ListItem>
                      <ListItem component={Link} href={venueData?.policy_link} target='_blank' rel='noopener' className={classes.footerText}>
                        <ListItemText primary={t('USAGE_TERMS')} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
                <Grid item direction='column' md={3} xs={12} sm={6} container justify='flex-start' alignItems='center' alignContent='center'>
                  <Grid item>
                    <Typography variant='h6' className={classes.footerText} align='center'>{t('SUPPORT')}</Typography>
                  </Grid>
                  <Grid item>
                    <List dense>
                      <ListItem component={Link} href={`tel:${venueData?.phone_number}`} target='_blank' rel='noopener' className={classes.footerText}>
                        <ListItemText primary={venueData?.phone_number} />
                      </ListItem>
                      <ListItem component={Link} href={`mailto:${venueData?.contact_email}`} target='_blank' rel='noopener' className={classes.footerText}>
                        <ListItemText primary={venueData?.contact_email} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </>
            }
          </Grid>
        </Container>
      </Paper>
    </div>
  )
};

