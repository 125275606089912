import { Config } from '../config';

const config = new Config();
const utils = {
  countryToFlag: (isoCode: string) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  },
  round: (aNumber: number) => Math.round((aNumber + Number.EPSILON) * 100) / 100,
  runAsync: (runnable: () => Promise<void>, onDone?: (error?: any) => void) => {
    runnable().then(() => {
      if (onDone) {
        onDone();
      }
    }).catch(e => {
      if (e) {
        console.error(e);
      }
      if (onDone) {
        onDone(e);
      }
    });
  },
  currencyFormat: (aNumber: number, fractionDigits: number = 2) => new Intl.NumberFormat(
    ['es-ES', 'en-GB', 'en-US'],
    { style: 'decimal', minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
  ).format(aNumber),


}
export default utils
