import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  List,
  ListItem,
  ListItemText,
  TextField,
  Link,
} from '@material-ui/core';
import { useContext, useApi } from '../context';
import { Redirect, useHistory } from 'react-router-dom';
import utils from '../services/utils.service';
import { AlertDialog } from '../components/alertDialog';
import { TransferWizard, TransferWizardStep } from './wizard';
import { Trans, useTranslation } from 'react-i18next';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/Money';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    listItem: {
      padding: theme.spacing(0.25, 1),
    },
    listItemText: {
      margin: theme.spacing(0)
    }
  }),
);


export const Payment = () => {
  const context = useContext();
  const api = useApi();
  const history = useHistory();

  const { t } = useTranslation(['checkout']);


  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [selectedPaymentMethod, selectPaymentMethod] = React.useState<string | undefined>('');
  const [paymentMethods, setPaymentMethods] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (context.data.venue) {
      setPaymentMethods(context.data.venue.payment_methods);
      if (context.data.venue.payment_methods.includes('REDSYS')) {
        selectPaymentMethod('REDSYS');
      } else if (context.data.venue.payment_methods.includes('ON_DELIVERY')){
        selectPaymentMethod('REDSYS');
      }
    }
  }, [context.data.venue]);

  const checkout = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const response = await api.checkout(  
        {
          vendor_id: context.data.vendorId!,
          venue_id: context.data.venue!.id,
          location: context.data.location!,
          requested_datetime: context.data.requestedDatetime?.dbValue!,
          products: context.cartActions.getLines().map(l => ({
            id: l.product.id,
            quantity: l.quantity
          })),
          payment_method: selectedPaymentMethod!
         
        },
        localStorage.getItem('customer_token')!
      );

      // Don't know if this is the best place to do this
      // Necessary for reseting state after sending data to the API
      context.data.requestedDatetime = undefined

      if (response.payment_url) {
        window.location.replace(response.payment_url);
      } else {
        history.push('/payments/ondelivery/ok');
      }
    }, (e) => {
      if (e) {
        setLoading(false);
        setError(true);
        
        // Don't know if this is the best place to do this
        // Necessary for reseting state after sending data to the API
        context.data.requestedDatetime = undefined
      }
    });
  }

  const classes = useStyles();

  if (context.cartActions.getTotalAmount() === 0 || !context.data.vendorId) {
    return (
      <Redirect to='/vendors' />
    )
  }

  if (!localStorage.getItem('customer_token')) {
    return (
      <Redirect to='/checkout/customer' />
    )
  }

  return (
    <TransferWizard
      canGoNext={!loading && selectedPaymentMethod !== null}
      loading={loading}
      back={() => history.goBack()}
      next={() => { checkout() }}
      step={TransferWizardStep.PAYMENT}
      showButtons={true}>
      <AlertDialog
        open={error}
        title={t('ERROR_TITLE')}
        message={t('PAYMENT_ERROR_CONTENT')}
        onClose={() => {
          setError(false)
        }}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>
            {t('PAYMENT_INTRO')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disableClearable={true}
            onChange={(event, value) => {
              selectPaymentMethod(value);
            }}
            value={selectedPaymentMethod}
            options={paymentMethods}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => t(option)}
            getOptionSelected={(option, value) => option === value}
            renderOption={(option) => (
              <React.Fragment>
                {option === 'REDSYS' ? <CreditCardIcon color='primary' /> : <MoneyIcon color='primary' />}
                <span style={{ marginLeft: 10 }}>{t(option)}</span>
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('PAYMENT_METHOD')}
                variant='outlined'
                error={false}
                helperText={false}
                fullWidth={true}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: selectedPaymentMethod === 'REDSYS' ? <CreditCardIcon color='primary' /> : <MoneyIcon color='primary' />
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'chrome-off',
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <List disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemText primary={t('AMOUNT')} primaryTypographyProps={{ variant: 'h6', component: 'span' }} />
              <Typography variant='h5' component='span'>{`${utils.currencyFormat(context.cartActions.getTotalAmount())} EUR`}</Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Trans t={t} i18nKey='TERMS+CONDITIONS'>
            <Typography variant='body2'>
              0 <Link href={context.data.venue?.policy_link} target='_blank' rel='noopener'>1</Link> 2
            </Typography>
          </Trans>
        </Grid>
      </Grid>
    </TransferWizard>
  );
}
