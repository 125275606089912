import React from 'react';
import {
  Typography,
  Grid,
  Container,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useContext } from '../context';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactMarkdown from 'react-markdown'


export const Home = () => {
  const theme = useTheme();
  const { t } = useTranslation('home');
  const context = useContext();

  const markDownRenderers = {
    img: ({
      ...props
    }) => (
      <img
        {...props}
        style={{ maxWidth: '100%', margin: '0 auto', display: 'block', marginBottom: theme.spacing(4) }} />
    ),
    p: ({ ...props }) => (
      <p style={{ fontSize: theme.typography.body1.fontSize }} {...props} />
    )
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant={'h2'} align='center'>
            {context.data.venue?.name}
          </Typography>
          <Typography variant={'h4'} align='center'>
            {context.data.location}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ReactMarkdown components={markDownRenderers}>
            {context.data.venue?.description ? context.data.venue.description : ''}
          </ReactMarkdown>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h4'} align='center'>
            {t('FAQ_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('HOW_DOES_IT_WORK_TITLE')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='HOW_DOES_IT_WORK_CONTENT' >
                <Typography>
                  {'0'}
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('HOW_DO_I_PAY_TITLE')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='HOW_DO_I_PAY_CONTENT' >
                <Typography component='span'>
                  {'0'}
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('SUPPORT_TITLE')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='SUPPORT_CONTENT' >
                <Typography>
                  {'0'}
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant={'h6'} component={'span'}>{t('REFUND_TITLE')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Trans t={t} i18nKey='REFUND_CONTENT' >
                <Typography>
                  {'0'}
                </Typography>
              </Trans>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  )
}